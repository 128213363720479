module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E5FRHWCPFC"],"gtagConfig":{"optimize_id":"G-E5FRHWCPFC","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/RWD_OK.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bf9f374e96c631f69275e8361ce47e0e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"askConsentAtCookiePolicyUpdate":true,"enableFadp":true,"enableLgpd":true,"fadpApplies":true,"floatingPreferencesButtonDisplay":"bottom-right","lang":"it","perPurposeConsent":true,"preferenceCookie":{"expireAfter":180},"siteId":3635383,"usprApplies":true,"whitelabel":false,"cookiePolicyId":21538021,"banner":{"acceptButtonCaptionColor":"#000000","acceptButtonColor":"#FFFFFF","acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonCaptionColor":"#000000","customizeButtonColor":"#C3C3C3","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"ownerName":"www.rovaiweber.it","position":"float-bottom-right","rejectButtonCaptionColor":"#000000","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true,"showTitle":false,"showTotalNumberOfProviders":true}},"googleTagManagerOptions":{"eventName":"custom_iubenda_event_name","dataLayerName":"customDataLayerName"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
